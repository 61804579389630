import React from "react";

const NationalAthleteCard = ({ athlete }) => {
  return (
    <div className="container flex items-center justify-between w-full my-4 bg-bayrak sm:rounded-lg">
      <div className="relative flex flex-col p-10 md:p-12 lg:p-16 gap-y-4">
        <h3 className="relative text-xl font-bold tracking-tight md:text-4xl text-write">
          {athlete.nameSurname}
        </h3>
        <div className="font-medium text-white text-md">{athlete.dan}</div>
      </div>
      {athlete.url !== "" && (
        <img
          className="relative hidden object-cover lg:block"
          src={athlete.url}
          alt={athlete.imageName}
        />
      )}
    </div>
  );
};

export default NationalAthleteCard;
