import React from "react";
import Routes from "./Routes";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import Modal from "./components/admin/Modal";

function App() {
  const { open, data } = useSelector((state) => state.modal);

  return (
    <div>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap"
        rel="stylesheet"
      />
      <link
        rel="stylesheet"
        type="text/css"
        charSet="UTF-8"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
      <Toaster />
      {open && <Modal name={open} data={data} />}

      <Routes />
    </div>
  );
}

export default App;
