import React, { useEffect, useState } from "react";
import { updateSensei, storage, deleteFile } from "../../../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import toast from "react-hot-toast";
import { VscLoading } from "react-icons/vsc";

export default function EditSenseiModal({ close, data }) {
  const [name, setName] = useState(data.name);
  const [cardText, setCardText] = useState(data.cardText);
  const [bioText, setBioText] = useState(data.bioText);
  const [bioImage, setBioImage] = useState(null);
  const [bioImageName, setBioImageName] = useState(data.bioImageName);
  const [image1, setImage1] = useState(null);
  const [image1Name, setImage1Name] = useState(data.image1Name);
  const [image2, setImage2] = useState(null);
  const [image2Name, setImage2Name] = useState(data.image2Name);
  const [image3, setImage3] = useState(null);
  const [image3Name, setImage3Name] = useState(data.image3Name);
  const [bioUrl, setBioUrl] = useState(data.bioUrl);
  const [url1, setUrl1] = useState(data.url1);
  const [url2, setUrl2] = useState(data.url2);
  const [url3, setUrl3] = useState(data.url3);
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);

  useEffect(() => {
    console.log(progress);
  }, [progress]);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const countHandle = async (sayac) => {
    for (let i = 0; i <= 100; i++) {
      await sleep(30);

      sayac(i);
    }
    toast.success("resim yüklendi");
  };

  const uploadImage = (
    image,
    file,
    setImage,
    setUrl,
    sayac,
    setImageName,
    imageName
  ) => {
    try {
      if (image == null) return;

      deleteFile(imageName, "sensei");
      setImageName(image.name);

      setProgress(0);

      const imageRef = ref(storage, `${file}/${image.name}`);
      uploadBytesResumable(imageRef, image).then((snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
        console.log(progress);
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          countHandle(sayac);
        });
      });

      setImage(null);
    } catch (error) {
      toast.error(error.code);
    }
  };

  const clickHandle = async (e) => {
    e.preventDefault();

    await updateSensei(data.id, {
      name,
      cardText,
      bioText,
      bioUrl,
      url1,
      url2,
      url3,
      bioImageName,
      image1Name,
      image2Name,
      image3Name,
    });

    close();
  };
  return (
    <div className="flex flex-col gap-y-2">
      <input
        type="text"
        placeholder="Sensei Adı"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <input
        type="text"
        placeholder="Anasayfa Kart Metin"
        value={cardText}
        onChange={(e) => setCardText(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <input
        type="text"
        placeholder="Biografi Kart Metin"
        value={bioText}
        onChange={(e) => setBioText(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <div className="w-full p-3 bg-gray-300 rounded-md">
        <div>Biografi Kart Resim</div>
        <div className="flex items-center justify-between gap-x-2">
          <input
            type="file"
            onChange={(e) => setBioImage(e.target.files[0])}
            className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
          />
          <button
            className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
            onClick={() =>
              uploadImage(
                bioImage,
                "sensei",
                setBioImage,
                setBioUrl,
                setCount,
                setBioImageName,
                bioImageName
              )
            }
            disabled={bioImage == null}
          >
            {count >= 1 && count <= 99 && (
              <div
                className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
                viewBox="0 0 24 24"
              >
                <VscLoading size={20} />
              </div>
            )}
            Yükle
          </button>
        </div>
        <img src={bioUrl} alt="resim" />
      </div>

      <div className="w-full p-3 bg-gray-300 rounded-md">
        <div>Resim 1</div>
        <div className="flex items-center justify-between gap-x-2">
          <input
            type="file"
            onChange={(e) => setImage1(e.target.files[0])}
            className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
          />
          <button
            className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
            onClick={() =>
              uploadImage(
                image1,
                "sensei",
                setImage1,
                setUrl1,
                setCount2,
                setImage1Name,
                image1Name
              )
            }
            disabled={image1 == null}
          >
            {count2 >= 1 && count2 <= 99 && (
              <div
                className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
                viewBox="0 0 24 24"
              >
                <VscLoading size={20} />
              </div>
            )}
            Yükle
          </button>
        </div>
        <img src={url1} alt="resim" />
      </div>

      <div className="w-full p-3 bg-gray-300 rounded-md">
        <div>Resim 2</div>
        <div className="flex items-center justify-between gap-x-2">
          <input
            type="file"
            onChange={(e) => setImage2(e.target.files[0])}
            className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
          />
          <button
            className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
            onClick={() =>
              uploadImage(
                image2,
                "sensei",
                setImage2,
                setUrl2,
                setCount3,
                setImage2Name,
                image2Name
              )
            }
            disabled={image2 == null}
          >
            {count3 >= 1 && count3 <= 99 && (
              <div
                className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
                viewBox="0 0 24 24"
              >
                <VscLoading size={20} />
              </div>
            )}
            Yükle
          </button>
        </div>
        <img src={url2} alt="resim" />
      </div>

      <div className="w-full p-3 bg-gray-300 rounded-md">
        <div>Resim 3</div>
        <div className="flex items-center justify-between gap-x-2">
          <input
            type="file"
            onChange={(e) => setImage3(e.target.files[0])}
            className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
          />
          <button
            className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
            onClick={() =>
              uploadImage(
                image3,
                "sensei",
                setImage3,
                setUrl3,
                setCount4,
                setImage3Name,
                image3Name
              )
            }
            disabled={image3 == null}
          >
            {count4 >= 1 && count4 <= 99 && (
              <div
                className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
                viewBox="0 0 24 24"
              >
                <VscLoading size={20} />
              </div>
            )}
            Yükle
          </button>
        </div>
        <img src={url3} alt="resim" />
      </div>

      <button
        onClick={clickHandle}
        className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
      >
        Kaydet
      </button>
    </div>
  );
}
