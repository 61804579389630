import React, { useEffect, useState } from "react";
import { updateSporcu, storage, deleteFile } from "../../../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import toast from "react-hot-toast";
import { VscLoading } from "react-icons/vsc";

export default function EditSporcuModal({ close, data }) {
  const [nameSurname, setNameSurname] = useState(data.nameSurname);
  const [dan, setDan] = useState(data.dan);
  const [antrenor, setAntrenor] = useState(data.antrenor);
  const [hakem, setHakem] = useState(data.hakem);
  const [senpai, setSenpai] = useState(data.senpai);
  const [milli, setMilli] = useState(data.milli);
  const [senpaiText, setSenpaiText] = useState(data.senpaiText);
  const [milliText, setMilliText] = useState(data.milliText);
  const [tarih, setTarih] = useState(data.tarih);
  const [image, setImage] = useState(data.image);
  const [imageName, setImageName] = useState(data.imageName);
  const [url, setUrl] = useState(data.url);
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(0);
  const [puan, setPuan] = useState(data.puan);
  const [turnuvalar, setTurnuvalar] = useState(data.turnuvalar);

  useEffect(() => {
    console.log(progress);
  }, [progress]);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const countHandle = async () => {
    for (let i = 0; i <= 100; i++) {
      await sleep(30);

      setCount(i);
    }
    toast.success("resim yüklendi");
  };

  const uploadImage = (image, file) => {
    try {
      if (image == null) return;

      deleteFile(imageName, "sporcular");
      setImageName(image.name);

      const imageRef = ref(storage, `${file}/${image.name}`);
      uploadBytesResumable(imageRef, image).then((snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
        console.log(progress);
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setUrl(downloadURL);
          countHandle();
        });
      });

      setImage(null);
    } catch (error) {
      toast.error(error);
    }
  };

  const checkHandle = (value) => {
    return !value;
  };

  const textCheck = () => {
    return "";
  };
  const listHandle = (list, eleman) => {
    const newList = [...list, eleman];
    setTurnuvalar(newList);
    setMilliText("");
  };

  const delHandle = async (liste, eleman) => {
    var arr = [...liste];
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] === eleman) {
        arr.splice(i, 1);
      }
    }
    setTurnuvalar(arr);
  };

  const clickHandle = async () => {
    await updateSporcu(data.id, {
      nameSurname,
      dan,
      antrenor,
      hakem,
      senpai,
      milli,
      senpaiText,
      tarih,
      url,
      imageName,
      puan,
      turnuvalar,
    });
    close();
  };

  return (
    <div className="flex flex-col gap-y-2">
      <input
        type="text"
        placeholder="İsim-Soyisim"
        value={nameSurname}
        onChange={(e) => setNameSurname(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />
      <div className="flex items-center justify-between gap-x-2">
        <input
          type="file"
          onChange={(e) => setImage(e.target.files[0])}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />
        <button
          className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
          onClick={() => uploadImage(image, "sporcular")}
          disabled={image == null}
        >
          {count >= 1 && count <= 99 && (
            <div
              className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
              viewBox="0 0 24 24"
            >
              <VscLoading size={20} />
            </div>
          )}
          Yükle
        </button>
      </div>

      <img src={url} alt="resim" />
      <input
        type="date"
        placeholder="gg/aa/yyyy"
        value={tarih}
        onChange={(e) => setTarih(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />
      <input
        type="number"
        value={puan}
        placeholder="Puan"
        onChange={(e) => setPuan(parseInt(e.target.value))}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />
      <input
        type="text"
        placeholder="Dan"
        value={dan}
        onChange={(e) => setDan(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <input
        type="text"
        placeholder="antrenörlük"
        value={antrenor}
        onChange={(e) => setAntrenor(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />
      <input
        type="text"
        placeholder="hakemlik"
        value={hakem}
        onChange={(e) => setHakem(e.target.value)}
        className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
      />

      <div className="flex items-center gap-x-2">
        <input
          type="checkbox"
          checked={senpai}
          onChange={() => setSenpai(checkHandle)}
          onClick={() => setSenpaiText(textCheck)}
        />
        <span>Senpai</span>
      </div>
      <div className="flex items-center gap-x-2">
        <input
          type="checkbox"
          checked={milli}
          onChange={() => setMilli(checkHandle)}
          onClick={() => setMilliText(textCheck)}
        />
        <span>Milli Sporcu</span>
      </div>
      {senpai === true ? (
        <input
          type="text"
          placeholder="Senpai Metin"
          value={senpaiText}
          disabled={senpai !== true}
          onChange={(e) => setSenpaiText(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />
      ) : (
        <div></div>
      )}
      {milli === true ? (
        <div className="w-full">
          <ul>
            {turnuvalar &&
              turnuvalar.map((turnuva) => (
                <li className="flex justify-between" key={0}>
                  {turnuva}
                  <button
                    className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
                    onClick={(e) => delHandle(turnuvalar, turnuva)}
                  >
                    sil
                  </button>
                </li>
              ))}
          </ul>

          <div className="flex gap-x-2">
            <input
              type="text"
              placeholder="Milli Sporcu Metin"
              disabled={milli !== true}
              value={milliText}
              onChange={(e) => setMilliText(e.target.value)}
              className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
            />
            <button
              disabled={milliText === ""}
              className="px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
              onClick={(e) => listHandle(turnuvalar, milliText)}
            >
              Ekle
            </button>
          </div>
        </div>
      ) : (
        <div></div>
      )}
      <button
        onClick={clickHandle}
        className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
      >
        Kaydet
      </button>
    </div>
  );
}
