import React from "react";
import SenseiPhotoArticle from "../components/ui/SenseiPhotoArticle";
import SenseiCard from "../components/Sensei";
import { useSelector } from "react-redux";
import "../firebase";

const Sensei = () => {
  const { sensei } = useSelector((state) => state.sensei);

  return (
    <div className="flex flex-col md:pl-4 gap-y-6">
      {sensei.map((sensei) => (
        <div className="flex flex-col md:pl-4 gap-y-6">
          <h1 className="pt-4 pl-4 text-2xl font-bold text-write">
            Shihan İsmail KESKİN
          </h1>
          <SenseiPhotoArticle img={sensei.bioUrl} text={sensei.bioText} />

          <div className="container flex flex-col items-center justify-between gap-10 p-3 mx-auto md:flex-row md:grid md:grid-cols-3 bg-secondary-write sm:rounded-lg">
            <img
              className="object-cover border-2 border-white rounded h-60"
              src={sensei.url1}
              alt={sensei.image1Name}
            />
            <img
              className="object-cover border-2 border-white rounded h-60"
              src={sensei.url2}
              alt={sensei.image2Name}
            />
            <img
              className="object-cover border-2 border-white rounded h-60"
              src={sensei.url3}
              alt={sensei.image3Name}
            />
          </div>

          <SenseiCard />
        </div>
      ))}
    </div>
  );
};

export default Sensei;
