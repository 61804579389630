import React from "react";
import Belt from "./ui/Belt";

const SenpaiCard = ({ senpai }) => {
  const belt = senpai.dan;
  return (
    <div className="flex items-center justify-between w-full my-4 sm:rounded-lg bg-brand-color">
      <div className="flex flex-col p-10 md:p-12 lg:p-16 gap-y-4">
        <h3 className="text-xl font-bold tracking-tight md:text-4xl text-write">
          {senpai.nameSurname}
        </h3>
        <Belt
          isDan={belt.split(".")[1].includes("Dan")}
          belt={Number(belt.split(".")[0])}
        />
        {senpai.hakem !== "" && (
          <div className="font-semibold text-md text-title">{senpai.hakem}</div>
        )}
        {senpai.antrenor !== "" && (
          <div className="font-semibold text-md text-title">
            {senpai.antrenor}
          </div>
        )}
        <p className="font-medium text-white md:text-lg text-md">
          {senpai.senpaiText}
        </p>
        <div className="py-2 text-lg font-semibold text-right text-white md:text-xl">
          OSU!
        </div>
      </div>
      {senpai.url !== "" && (
        <img
          className="hidden object-cover lg:block"
          src={senpai.url}
          alt={senpai.imageName}
        />
      )}
    </div>
  );
};

export default SenpaiCard;
