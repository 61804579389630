import React from "react";

const Belt = ({ belt, isDan }) => {
  return (
    <div
      className={`flex justify-between px-3 ${
        isDan
          ? "bg-black"
          : belt === 1 || belt === 2
          ? "bg-brown"
          : belt === 3 || belt === 4
          ? "bg-green-600"
          : belt === 5 || belt === 6
          ? "bg-yellow-400"
          : belt === 7 || belt === 8
          ? "bg-blue-600"
          : belt === 9 || belt === 10
          ? "bg-orange-500"
          : "bg-orange-500"
      } rounded w-60`}
    >
      <div></div>
      <div
        className={`text-lg font-bold ${
          isDan
            ? "text-write"
            : belt === 1 || belt === 2
            ? "text-write"
            : belt === 3 || belt === 4
            ? "text-write"
            : belt === 5 || belt === 6
            ? "text-brand-color"
            : belt === 7 || belt === 8
            ? "bg-blue-600"
            : belt === 9 || belt === 10
            ? "bg-orange-500"
            : "bg-orange-500"
        } `}
      >
        {isDan ? `${belt}. Dan` : `${belt}. Kyu`}
      </div>
      <div className="flex gap-x-1">
        {isDan ? (
          [...Array(belt)].map((_, i) => (
            <div key={i} className="bg-gold w-[4px] h-full"></div>
          ))
        ) : belt === 1 ||
          belt === 3 ||
          belt === 5 ||
          belt === 7 ||
          belt === 9 ? (
          <div className="bg-black w-[4px] h-full"></div>
        ) : null}
      </div>
    </div>
  );
};

export default Belt;
