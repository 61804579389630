import React from "react";
import logo from "../images/keskin.png";

const AboutUs = () => {
  return (
    <section>
      <div className="relative h-auto my-1 before:bg-brand-color before:bg-opacity-80 before:absolute before:inset-0 before:w-full before:h-full before:z-10">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/baywan-427e3.appspot.com/o/photos%2Ftak%C4%B1m.JPG?alt=media&token=326d1558-bb02-48c9-b681-dc24b7bca821"
          alt="about us"
          className="object-cover w-full h-[500px]"
        />

        <div className="container absolute top-0 z-10 flex flex-col items-center justify-center h-full -translate-x-1/2 gap-y-3 gap-x-5 md:gap-10 md:grid md:grid-cols-12 left-1/2">
          <h2 className="flex justify-center col-span-5 text-2xl text-title md:text-4xl md:mt-0">
            Biz Kimiz?
          </h2>
          <p className="col-span-7 px-5 font-medium text-white md:py-8 text-md md:text-lg text-center">
            Keskin Dojo, Kyokushin Karate'nin disiplin, özveri ve ahlak
            değerlerini benimseyen bir eğitim merkezi olarak, dövüş sanatlarında
            mükemmeliyeti hedefleyen sporculara ev sahipliği yapmaktadır.
            Dojo’muzun lideri ve kurucusu, 5. Dan seviyesine ulaşmış, 3. Kademe
            Kyokushin Antrenörü İsmail Keskin, yalnızca teknik beceri geliştirme
            konusunda değil, aynı zamanda sporcuların kişisel gelişimlerine de
            rehberlik eden bir ustadır. Yıllar içinde elde ettiğimiz ulusal ve
            uluslararası başarılarla Kyokushin Karate'nin ruhunu yaymaya devam
            ediyoruz. Sporcularımız sadece fiziksel becerilerini geliştirmekle
            kalmıyor, aynı zamanda bu sanatın etik değerlerini ve saygı
            felsefesini de içselleştiriyor.
          </p>
        </div>
      </div>
      <br />

      <div className="mx-auto my-1 md:my-5 md:grid md:grid-cols-12 md:p-0">
        <div className="flex flex-col justify-center col-span-12 gap-5 px-8 md:col-span-6">
          <h1 className="flex justify-center mb-0 text-2xl font-semibold text-write">
            Misyonumuz
          </h1>
          <div className="flex justify-center w-2/3 mx-auto text-lg font-mediu text-center">
            Keskin Dojo olarak misyonumuz, her yaştan bireyin kendi
            potansiyelini keşfetmesini sağlamak ve Kyokushin Karate'nin sadece
            bir dövüş sanatı değil, aynı zamanda bir yaşam biçimi olduğunu
            göstermektir. Sporcularımıza dünya çapında başarılar kazandırırken,
            onların disiplinli, saygılı ve kararlı bireyler olarak gelişmelerine
            destek oluyoruz. Bu yolda hem teknik hem de manevi açıdan güçlü
            bireyler yetiştirmek, dostluk, dayanışma ve paylaşımı temel alan bir
            dojo kültürünü sürdürmek en büyük önceliğimizdir.
          </div>
        </div>
        <picture className="justify-center hidden col-span-6 md:flex">
          <img src={logo} alt="logo" className="object-cover h-80" />
        </picture>
      </div>
      <br />
    </section>
  );
};

export default AboutUs;
