import React, { useEffect, useState } from "react";
import { addSensei, storage } from "../../../firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import toast from "react-hot-toast";
import { VscLoading } from "react-icons/vsc";

export default function SenseiForm() {
  const [name, setName] = useState("");
  const [cardText, setCardText] = useState("");
  const [bioText, setBioText] = useState("");
  const [bioImage, setBioImage] = useState(null);
  const [bioImageName, setBioImageName] = useState("");
  const [image1, setImage1] = useState(null);
  const [image1Name, setImage1Name] = useState("");
  const [image2, setImage2] = useState(null);
  const [image2Name, setImage2Name] = useState("");
  const [image3, setImage3] = useState(null);
  const [image3Name, setImage3Name] = useState("");
  const [bioUrl, setBioUrl] = useState("");
  const [url1, setUrl1] = useState("");
  const [url2, setUrl2] = useState("");
  const [url3, setUrl3] = useState("");
  const [progress, setProgress] = useState(0);
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [count3, setCount3] = useState(0);
  const [count4, setCount4] = useState(0);

  useEffect(() => {
    console.log(progress);
  }, [progress]);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const countHandle = async (sayac) => {
    for (let i = 0; i <= 100; i++) {
      await sleep(30);

      sayac(i);
    }
    toast.success("resim yüklendi");
  };

  const uploadImage = (image, file, setImage, setUrl, sayac, setImageName) => {
    try {
      if (image == null) return;

      setProgress(0);

      const imageRef = ref(storage, `${file}/${image.name}`);
      uploadBytesResumable(imageRef, image).then((snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
        console.log(progress);
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setImageName(image.name);
          setUrl(downloadURL);
          countHandle(sayac);
        });
      });

      setImage(null);
    } catch (error) {
      toast.error(error.code);
    }
  };

  const submitHandle = async (e) => {
    e.preventDefault();

    await addSensei({
      name,
      cardText,
      bioText,
      bioUrl,
      url1,
      url2,
      url3,
      bioImageName,
      image1Name,
      image2Name,
      image3Name,
    });

    setName("");
    setCardText("");
    setBioText("");
    setBioUrl("");
    setUrl1("");
    setUrl2("");
    setUrl3("");
    setBioImage(null);
    setBioImageName("");
    setImage1(null);
    setImage1Name("");
    setImage2(null);
    setImage2Name("");
    setImage3(null);
    setImage3Name("");
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-col items-center gap-4 px-10 py-5 my-8 bg-gray-200 rounded-md ">
        <h2 className="text-2xl font-semibold">Sensei Ekle</h2>

        <input
          type="text"
          placeholder="Sensei Adı"
          value={name}
          onChange={(e) => setName(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <input
          type="text"
          placeholder="Anasayfa Kart Metin"
          value={cardText}
          onChange={(e) => setCardText(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <input
          type="text"
          placeholder="Biografi Kart Metin"
          value={bioText}
          onChange={(e) => setBioText(e.target.value)}
          className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
        />

        <div className="w-full p-3 bg-gray-300 rounded-md">
          <div>Biografi Kart Resim</div>
          <div className="flex items-center justify-between gap-x-2">
            <input
              type="file"
              onChange={(e) => setBioImage(e.target.files[0])}
              className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
            />
            <button
              className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
              onClick={() =>
                uploadImage(
                  bioImage,
                  "sensei",
                  setBioImage,
                  setBioUrl,
                  setCount,
                  setBioImageName
                )
              }
              disabled={bioImage == null}
            >
              {count >= 1 && count <= 99 && (
                <div
                  className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
                  viewBox="0 0 24 24"
                >
                  <VscLoading size={20} />
                </div>
              )}
              Yükle
            </button>
          </div>
        </div>

        <div className="w-full p-3 bg-gray-300 rounded-md">
          <div>Resim 1</div>
          <div className="flex items-center justify-between gap-x-2">
            <input
              type="file"
              onChange={(e) => setImage1(e.target.files[0])}
              className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
            />
            <button
              className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
              onClick={() =>
                uploadImage(
                  image1,
                  "sensei",
                  setImage1,
                  setUrl1,
                  setCount2,
                  setImage1Name
                )
              }
              disabled={image1 == null}
            >
              {count2 >= 1 && count2 <= 99 && (
                <div
                  className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
                  viewBox="0 0 24 24"
                >
                  <VscLoading size={20} />
                </div>
              )}
              Yükle
            </button>
          </div>
        </div>

        <div className="w-full p-3 bg-gray-300 rounded-md">
          <div>Resim 2</div>
          <div className="flex items-center justify-between gap-x-2">
            <input
              type="file"
              onChange={(e) => setImage2(e.target.files[0])}
              className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
            />
            <button
              className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
              onClick={() =>
                uploadImage(
                  image2,
                  "sensei",
                  setImage2,
                  setUrl2,
                  setCount3,
                  setImage2Name
                )
              }
              disabled={image2 == null}
            >
              {count3 >= 1 && count3 <= 99 && (
                <div
                  className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
                  viewBox="0 0 24 24"
                >
                  <VscLoading size={20} />
                </div>
              )}
              Yükle
            </button>
          </div>
        </div>

        <div className="w-full p-3 bg-gray-300 rounded-md">
          <div>Resim 3</div>
          <div className="flex items-center justify-between gap-x-2">
            <input
              type="file"
              onChange={(e) => setImage3(e.target.files[0])}
              className="w-full px-4 text-sm transition-colors border-2 border-gray-200 rounded outline-none h-14 group-hover:border-orange-500 focus:border-orange-500 peer"
            />
            <button
              className="flex items-center justify-between p-2 px-6 font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600 text-md"
              onClick={() =>
                uploadImage(
                  image3,
                  "sensei",
                  setImage3,
                  setUrl3,
                  setCount4,
                  setImage3Name
                )
              }
              disabled={image3 == null}
            >
              {count4 >= 1 && count4 <= 99 && (
                <div
                  className="flex items-start justify-center w-5 h-5 mr-3 bg-white rounded-full animate-spin"
                  viewBox="0 0 24 24"
                >
                  <VscLoading size={20} />
                </div>
              )}
              Yükle
            </button>
          </div>
        </div>

        <div>
          <button
            onClick={submitHandle}
            className="p-1 px-3 text-lg font-semibold text-orange-600 transition-colors bg-gray-600 rounded-md hover:bg-orange-500 hover:text-gray-600"
          >
            Ekle
          </button>
        </div>
      </div>
    </div>
  );
}
