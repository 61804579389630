import React from "react";
import { useWindowWidth } from "@react-hook/window-size";
import HeroSection from "../components/HeroSection";
import Categories from "../components/Categories";
import News from "../components/News";
import Sensei from "../components/Sensei";
import Standings from "./Standings";

const Main = () => {
  const onlyWidth = useWindowWidth();

  return (
    <div>
      {onlyWidth < 768 && <News />}
      <HeroSection />
      <Categories />
      {onlyWidth >= 768 && <News />}

      <div className="container grid pt-2 mx-auto md:pt-8 md:gap-y-8 gap-y-4">
        <Sensei />

        <Standings />
      </div>
    </div>
  );
};

export default Main;
