import React from "react";
import SenpaiCard from "../components/SenpaiCard";

import { useSelector } from "react-redux";
import "../firebase";

const Senpai = () => {
  const { sporcular } = useSelector((state) => state.sporcular);

  const order = [
    "10.Dan",
    "9.Dan",
    "8.Dan",
    "7.Dan",
    "6.Dan",
    "5.Dan",
    "4.Dan",
    "3.Dan",
    "2.Dan",
    "1.Dan",
    "1.Kyu",
    "2.Kyu",
    "3.Kyu",
    "4.Kyu",
    "5.Kyu",
    "6.Kyu",
    "7.Kyu",
    "8.Kyu",
    "9.Kyu",
    "10.Kyu",
  ];

  const sortedSporcular = sporcular
    .filter((sporcu) => sporcu.senpai === true)
    .sort((a, b) => order.indexOf(a.dan) - order.indexOf(b.dan));

  return (
    <section className="container mx-auto md:px-6">
      <h1 className="pt-4 pl-4 text-2xl font-bold text-write">Senpai</h1>
      {sortedSporcular.map((sporcu) => (
        <SenpaiCard senpai={sporcu} key={sporcu.id} />
      ))}
    </section>
  );
};

export default Senpai;
