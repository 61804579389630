import React, { useState } from "react";
import { Collapse } from "react-collapse";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useSelector } from "react-redux";
import Standing from "../components/Standing";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import "../firebase";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

const Standings = () => {
  const { sporcular } = useSelector((state) => state.sporcular);
  const { photos } = useSelector((state) => state.photos);

  const url_list = photos.map((photo) => photo.url);

  const sıralama = [...sporcular];

  sıralama.sort(function(a, b) {
    return b.puan - a.puan;
  });

  const [isOpen, setIsOpen] = useState(true);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };
  const keskinDojoBasarilari = [
    {
      no: 1,
      sampiyonalar: "Dünya Şampiyonası",
      birincilikler: 0,
      ikincilikler: 2,
      ucunculukler: 2,
    },
    {
      no: 2,
      sampiyonalar: "Avrupa Şampiyonası",
      birincilikler: 5,
      ikincilikler: 5,
      ucunculukler: 8,
    },
    {
      no: 3,
      sampiyonalar: "Balkan Şampiyonası",
      birincilikler: 19,
      ikincilikler: 16,
      ucunculukler: 10,
    },
    {
      no: 4,
      sampiyonalar: "Uluslararası Şampiyonalar",
      birincilikler: 14,
      ikincilikler: 16,
      ucunculukler: 13,
    },
    {
      no: 5,
      sampiyonalar: "Türkiye Şampiyonaları",
      birincilikler: 106,
      ikincilikler: 86,
      ucunculukler: 100,
    },
  ];

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    value,
  }) => {
    if (value === 0) return null;

    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return (
      <text
        x={x}
        y={y}
        fill="black"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}% (${value})`}
      </text>
    );
  };

  const renderPieCharts = () => {
    return keskinDojoBasarilari.map((item, index) => {
      const data = [
        { name: "Birincilikler", value: item.birincilikler },
        { name: "İkincilikler", value: item.ikincilikler },
        { name: "Üçüncülükler", value: item.ucunculukler },
      ];

      return (
        <div key={index} className="p-4">
          <h3 className="text-center font-bold mb-4">{item.sampiyonalar}</h3>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                labelLine={false}
                outerRadius="80%"
                fill="#8884d8"
                dataKey="value"
                label={renderCustomizedLabel}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      );
    });
  };

  return (
    <div className="container pt-4 mx-auto">
      <h1 className="pl-4 text-2xl font-bold text-write">
        Keskin Dojo İstatistikleri
      </h1>

      <div className="flex justify-center mt-4">
        <div className="flex items-center mr-4">
          <div
            className="w-2 h-2 rounded-full mr-2"
            style={{
              backgroundColor: COLORS[0],
              width: "10px",
              height: "10px",
            }}
          ></div>
          <span>Birincilikler</span>
        </div>
        <div className="flex items-center mr-4">
          <div
            className="w-2 h-2 rounded-full mr-2"
            style={{
              backgroundColor: COLORS[1],
              width: "10px",
              height: "10px",
            }}
          ></div>
          <span>İkincilikler</span>
        </div>
        <div className="flex items-center">
          <div
            className="w-2 h-2 rounded-full mr-2"
            style={{
              backgroundColor: COLORS[2],
              width: "10px",
              height: "10px",
            }}
          ></div>
          <span>Üçüncülükler</span>
        </div>
      </div>

      <div className="mt-8 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {renderPieCharts()}
      </div>
      <button
        onClick={toggleCollapse}
        className="flex items-center pb-5 pl-8 text-xl font-bold transition-all md:text-2xl text-write group hover:text-brand-color"
      >
        Sporcu Sıralaması
        <button className="grid w-6 h-6 pl-2 rounded-lg place-items-center">
          <span
            className={`transition-all transform ${
              isOpen ? "-rotate-180" : ""
            }`}
          >
            <MdKeyboardArrowDown
              className="group-hover:text-brand-color"
              size={24}
            />
          </span>
        </button>
      </button>
      <Collapse isOpened={isOpen}>
        <div className="flex items-center justify-between px-8">
          <div className="text-lg font-semibold md:text-2xl text-brand-color">
            <div>Adı Soyadı</div>
            <div className="text-write">Kemer</div>
          </div>
          <div className="text-lg font-semibold text-red-500 md:text-2xl">
            Puan
          </div>
        </div>
        <hr className="h-[2px] bg-brand-color my-8" />

        {sıralama.map(
          (sporcu) => sporcu.puan !== "" && <Standing person={sporcu} />
        )}
      </Collapse>
      <img
        className={`transition-all transform object-cover w-full ${
          isOpen ? "hidden" : "block"
        }`}
        src={url_list[Math.floor(Math.random() * (url_list.length - 0) + 0)]}
        alt="resim"
      />
    </div>
  );
};

export default Standings;
